<template>
  <div>

    <div class="jumbotron jumbotron-fluid title__secondary">
      <div class="container"><h1 class="display-4 title-center">Betaalverzoek Check</h1>
        <p class="lead--centered">
          Betaalverzoekje ontvangen? Tegenwoordig de normaalste zaak van de wereld. Maar, voordat je ‘m betaalt, check dan even of alles in orde is.
        </p></div>
    </div>
    <div class="grey-bg pd-60">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-lg-5 mb-3">
            <div class="featured-list">
              <h2>
                In het kort:
              </h2>
              <ul>
                <li>
                  Je checkt nu gemakkelijk betaalverzoeken met Checkjelinkje
                </li>
                <li>
                  Je ziet meteen de begunstigde en kunt controleren of dat diegene is die je verwacht
                </li>
                <li>
                  Wanneer we het kunnen checken, laten we je ook zien of de begunstigde een organisatie of persoon is
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-12 col-lg-7">
            <video
                controls
                ref="video"
                src="https://assets.checkjelinkje.nl/videos/payment-request-check/payment-request-check-nl_NL.mp4"
                type="video/mp4"
            ></video>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-5">
            <router-link to="/" class="btn btn-outline-primary btn-lg">
              Check een betaalverzoekje <i class="far fa-arrow-right"></i>
            </router-link>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <h3>
              Betaalverzoek Check, van Checkjelinkje
            </h3>
            <p>
              Phishing geldt al jaren als een van de snelst groeiende problemen binnen de (cyber)criminaliteit. Phishingmails worden op steeds grotere schaal verstuurd uit naam van overheidsinstellingen, bedrijven of banken en zijn vaak niet meer van echt te onderscheiden. Het aantal slachtoffers dat hiermee wordt gemaakt, stijgt explosief. Om consumenten te helpen zichzelf te beschermen tegen phishing is Betaalverzoek Check ontwikkeld. Met de Betaalverzoek Check kunnen gebruikers informatie over een betaalverzoek dat zij per mail hebben ontvangen rechtstreeks inzien en de echtheid ervan controleren.
            </p>
            <br>
            <div class="alert alert-warning">
                De Betaalverzoek Check controleert dus of het betaalverzoek in een officiële bankomgeving is gemaakt. Je moet wel altijd alert blijven, omdat phishers
                ook katvangers kunnen gebruiken om via legitieme betaalverzoeken geld weg te sluizen. Dat het betaalverzoek echt is betekent dus niet altijd dat het ook legitiem is.
            </div>
          </div>
        </div>
        <br><br>
        <div class="row">
          <div class="col-md-12">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Betaalverzoek Check',
  // mounted: async function() {
  //   document.body.classList.add('body--privacy');
  //   document.querySelector('footer').classList.add('footer--white');
  // },
  // beforeDestroy: async function() {
  //   document.body.classList.remove('body--privacy');
  //   document.querySelector('footer').classList.remove('footer--white');
  // },
};
</script>
<style scoped>
.featured-list {
  border-radius: 7px;
}
.collapsable {
  border: 0;
  border-radius: 0;
}

.collapsable > ul {
  margin-bottom: 0;
}

video {
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15)
}
</style>
